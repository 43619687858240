import Vue from 'vue'
import Vuex from 'vuex'
import menuStore from './modules/menuStore'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

    modules: {

        menuStore
    }
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
})
