import axios from "axios";
import qs from "qs";
import global from "../components/Global";
import {MessageBox} from 'element-ui';
import store from '../store'
import {getToken} from "./auth";
import router from '@/router';


// 为axios添加异步请求头
axios.defaults.headers = {

    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json;charset=utf-8"
};

axios.defaults.withCredentials = true;
axios.defaults.timeout = 60000;

// 请求前进行拦截
axios.interceptors.request.use(function (config) {

    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['Authorization'] = 'Bearer ' + getToken();
    if (config.method === 'get' && config.data || config.ping) {
        let url = config.url + '?' + tansParams(config.data) + new Date().getTime();
        url = url.slice(0, -1);
        config.data = {};
        config.url = url;
    }
    return config
}, function (error) {

    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {

    if (response.data.code == global.response_status_login_msg) {

        // 设置登陆标志为未登录
        store.commit("saveIsLogin",false);
        // 异步请求地址，但是没有登陆，直接返回系统登陆页面
        router.push({path: '/'});
    } else if (response.data.code == global.response_status_authentication_msg) {

        // 设置登陆标志为未登录
        store.commit("saveIsLogin",false);
        // 异步请求地址，但是没有登陆，直接返回系统登陆页面
        router.push({path: '/'});
    } else if (response.data.code == global.response_status_exception_msg) {

        // 500错误提示信息
        MessageBox({

            title: '提示信息',
            message: response.data.msg
        });

    } else if (response.data.code == global.response_status_limit_msg) {

        // 权限限制
        MessageBox({

            title: '提示信息',
            message: response.data.msg
        });
        return response.data;

    } else if (response.data.code == global.response_status_service_msg) {

        // serviceException 返回全数据
        return response.data;

    } else if (response.data.code == global.response_status_auth_msg) {

        // 异步请求时，没有权限，要给出权限不足的提示信息
        MessageBox({

            title: '提示信息',
            message: response.data.msg
        });
        return response.data;
    } else {

        return response.data;
    }
}, function (error) {
    // 对响应错误做处理
    return Promise.reject(error);
});

export default axios

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){

    return new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)
        })
    });}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err =>{
                reject(err.data)
            })
    });
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}
