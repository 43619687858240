<script>
    const serverSite = process.env.VUE_APP_HTTP_URL;
    const mqttServerSite = process.env.VUE_APP_MATT_URL;
    console.log("HTTP请求地址======" + process.env.VUE_APP_HTTP_URL);
    console.log("MQTT请求地址======" + process.env.VUE_APP_MATT_URL);
    const serverBase = serverSite + "/doartIot/es-manager";
    const esaioServerBase = serverSite + "/doartIot/esaio-manager";
    const doartSystemBase = serverSite + "/doartIot/doart-system";
    const mqttServerUserName = "shangjc";
    const mqttServerPassword = "shang1234";
    const mqttDeviceCmdTopicPrefix = "$app_sub_topic/";
    const mqttClientIdPrefix = "es-manager";
    /**
     * 地区-主页路径，不同地区用不同登录页url
     */
    const areaOptions = [
        {
            url: 'https://www.doart-rockcore.com/manager/#/',
            areaName: '中国'
        },
        {
            url: 'https://pk.doart-rockcore.com/pkManager/#/',
            areaName: 'Pakistan'
        },
        {
            url: 'https://it.doart-rockcore.com/itManager/#/',
            areaName: 'Italia'
        }
    ];

    /**
     * 响应成功，服务器返回成功的提示信息.
     */
    const response_status_success_msg = 100;

    /**
     * 响应成功，服务器返回执行结果数据信息.
     */
    const response_status_success_obj = 200;

    /**
     * 鉴权异常.
     */
    const response_status_authentication_msg = 401;

    /**
     * 权限限制.
     */
    const response_status_limit_msg = 403;

    /**
     * 服务器处理异常.
     */
    const response_status_exception_msg = 500;

    /**
     * 业务处理异常.
     */
    const response_status_service_msg = 600;

    /**
     * 用户权限不足.
     */
    const response_status_auth_msg = 700;

    /**
     * 用户未登录.
     */
    const response_status_login_msg = 800;

    /**
     * 响应成功，服务器正常处理，返回错误的提示信息.
     */
    const response_status_error_msg = 900;

    /*const provinces = {

        '安徽': '/js/echarts/province//anhui.json',
        '澳门': '/js/echarts/province/aomen.json',
        '北京': '/js/echarts/province/beijing.json',
        '重庆': '/js/echarts/province/chongqing.json',
        '福建': '/js/echarts/province/fujian.json',
        '甘肃': '/js/echarts/province/gansu.json',
        '广东': '/js/echarts/province/guangdong.json',
        '广西': '/js/echarts/province/guangxi.json',
        '贵州': '/js/echarts/province/guizhou.json',
        '海南': '/js/echarts/province/hainan.json',
        '河北': '/js/echarts/province/hebei.json',
        '黑龙江': '/js/echarts/province/heilongjiang.json',
        '河南': '/js/echarts/province/henan.json',
        '湖北': '/js/echarts/province/hubei.json',
        '湖南': '/js/echarts/province/hunan.json',
        '江苏': '/js/echarts/province/jiangsu.json',
        '江西': '/js/echarts/province/jiangxi.json',
        '吉林': '/js/echarts/province/jilin.json',
        '辽宁': '/js/echarts/province/liaoning.json',
        '内蒙古': '/js/echarts/province/neimenggu.json',
        '宁夏': '/js/echarts/province/ningxia.json',
        '青海': '/js/echarts/province/qinghai.json',
        '山东': '/js/echarts/province/shandong.json',
        '上海': '/js/echarts/province/shanghai.json',
        '山西': '/js/echarts/province/shanxi.json',
        '陕西': '/js/echarts/province/shanxi1.json',
        '四川': '/js/echarts/province/sichuan.json',
        '台湾': '/js/echarts/province/taiwan.json',
        '天津': '/js/echarts/province/tianjin.json',
        '香港': '/js/echarts/province/xianggang.json',
        '新疆': '/js/echarts/province/xinjiang.json',
        '西藏': '/js/echarts/province/xizang.json',
        '云南': '/js/echarts/province/yunnan.json',
        '浙江': '/js/echarts/province/zhejiang.json'
    }*/

    export default {

        serverSite,
        serverBase,
        esaioServerBase,
        doartSystemBase,
        mqttServerSite,
        mqttServerUserName,
        mqttServerPassword,
        mqttDeviceCmdTopicPrefix,
        response_status_success_msg,
        response_status_success_obj,
        response_status_exception_msg,
        response_status_authentication_msg,
        response_status_limit_msg,
        response_status_service_msg,
        response_status_auth_msg,
        response_status_login_msg,
        response_status_error_msg,
        mqttClientIdPrefix,
        areaOptions
        // provinces
    }
</script>
