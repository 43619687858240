import Vue from 'vue'
import Vuex from 'vuex'
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {getToken} from "@/api/auth";

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        // 端点url
        url: '',
        // 链路检查定时器
        checkInterval: null,
        // sockjs对象
        sockJs: null,
        // stomp客户端对象
        stompClient: null,
        // 订阅列表，格式为 {topic:'', callback: {}, id:''}
        listenerList: [],
        // 是否产生控制台日志，生产环境可设置成false
        debug: false,
    },
    getters: {

        stompClient(state) {

            return function () {

                return state.stompClient;
            }
        },
        listenerList(state) {

            return function () {

                return state.listenerList;
            }
        }
    },
    mutations: {
        // 初始化websocket
        init(state, url) {

            if (state.stompClient == null || !state.stompClient.connected) {

                state.url = url;
                // 如果状态为已经连接，则断线重连
                if (state.stompClient != null && state.sockJs.readyState === SockJS.OPEN) {

                    state.stompClient.disconnect(() => {

                        this.commit('connect');
                    })
                // 如果状态为连接中，则等待连接完成
                } else if (state.stompClient != null && state.sockJs.readyState === SockJS.CONNECTING) {

                    this.commit('console', "连接正在建立，请等待...");
                    return;
                // 其余状态直接连接
                } else {

                    this.commit('connect');
                }
                // 如果链路检查定时器未创建，则创建一个新的，定时检测到链路正确创建后关闭定时器
                if (!state.checkInterval) {

                    state.checkInterval = setInterval(() => {

                        this.commit('console', "链路检测，当前状态为->" + state.sockJs.readyState);
                        if (state.stompClient != null && state.stompClient.connected) {

                            clearInterval(state.checkInterval);
                            state.checkInterval = null;
                            this.commit('console', '链路已建立');
                        } else if (state.stompClient != null && state.sockJs.readyState !== SockJS.CONNECTING) {

                            //经常会遇到websocket的状态为open 但是stompClient的状态却是未连接状态，故此处需要把连接断掉 然后重连
                            state.stompClient.disconnect(() => {

                                this.commit('console', '链路已断开，开始重新连接...');
                                this.commit('connect');
                            })
                        }
                    }, 2000);
                }
            } else {

                this.commit('console', '链路已建立，不再执行初始化操作...');
            }
        },
        // websocket连接
        connect(state) {

            const _this = this;
            const sockJs = new SockJS(state.url);
            state.sockJs = sockJs;
            // 获取STOMP子协议的客户端对象
            const stompClient = Stomp.over(sockJs);
            //关闭控制台打印
            stompClient.debug = null;
            stompClient.heartbeat.outgoing = 20000;
            //客户端不从服务端接收心跳包
            stompClient.heartbeat.incoming = 0;
            // 向服务器发起websocket连接
            stompClient.connect (
                {
                    'Content-Type' : 'application/json;charset=utf-8',
                    'Authorization' : getToken()
                },
                () => {

                    this.commit('console', '连接成功,信息如下 ↓');
                    this.commit('console', stompClient);
                    state.listenerList.forEach(item => {

                        state.stompClient.subscribe(item.topic, item.callback, {id: item.id});
                    })
                },
                (err) => {

                    this.commit('console', '连接失败,信息如下↓');
                    this.commit('console', err);
                    setTimeout(() => {

                        _this.commit('init', state.url);
                    }, 1000);
                }
            );
            state.stompClient = stompClient;
        },
        // 发送websocket消息
        send(state, p) {

            state.stompClient.send(p.topic, {}, p.data);
        },
        // 添加订阅
        addListener(state, subInfo) {

            state.listenerList.push(subInfo);
        },
        // 取消websocket订阅
        unSub(state, p) {

            if (state) {

                // 取消订阅
                let id = ""
                for (let item in state.stompClient.subscriptions) {

                    if (item.endsWith(p)) {

                        id = item
                        break;
                    }
                }
                state.stompClient.unsubscribe(id);
                // 删除订阅列表
                for (let i = 0; i < state.listenerList.length; i++) {

                    if (state.listenerList[i].topic == p) {

                        state.listenerList.splice(i,1);
                        this.commit('console', "取消订阅成功 ->" + p + "，剩余订阅数量 ->" + state.listenerList.length);
                        break;
                    }
                }
            }
        },
        // debug模式下输出日志
        console(state, msg) {

            if (state.debug) {

                console.log(msg);
            }
        },
        // 设置控制台日志模式
        setIsDebug(state, isDebug) {

            state.debug = isDebug;
        },
        // 销毁ws连接
        destroy(state) {

            // 清空连接rul
            state.url= '';
            // 先清除断线重连定时器
            // 主动断开stomp连接
            console.error(state.stompClient != null)
            console.error(state.sockJs.readyState)
            if (state.stompClient != null && state.sockJs.readyState === SockJS.OPEN) {

                state.stompClient.disconnect(() => {

                    this.commit('console', '已主动断开连接...');
                })
            }
            state.checkInterval = null;
            // 重置sockJs对象
            state.sockJs = null;
            // 重置stompClient对象
            state.stompClient = null;
            // 清楚订阅列表
            state.listenerList = [];
            // 恢复debug模式
            state.debug = false;
        }
    },
    actions: {

        init({commit}, url) {

            commit('init', url);
        },
        send({commit}, p) {

            commit('send', p);
        },
        addListener({commit}, subInfo) {

            commit('addListener', subInfo);
        },
        unSub({commit}, p) {

            commit('unSub', p);
        },
        setIsDebug({commit}, isDebug) {

            commit('setIsDebug', isDebug);
        },
        destroy({commit}){

            commit("destroy");
        }
    }
})