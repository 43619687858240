import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/';

Vue.use(Router);

const routes = [
    {
        name: 'login',
        path: '/',
        component: () => import('../views/Login.vue')
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('../views/Home.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                name: 'main',
                path: '/home',
                component: () => import('../views/analysis/Main.vue')
            }
        ]
    }
];

const router = new Router({

    routes : routes
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (!store.state.menuStore.isLogin) {

            next({path: '/'});
        } else {

            next();
        }
    } else {

        next();
    }
});

export default router;
