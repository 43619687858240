import Vue from "vue";
import App from './App'
import router from './router'
import Router from 'vue-router'
import store from './store'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css';
import 'font-awesome/css/font-awesome.min.css';
import axios from './api/baseApi'
import websocket from './store/modules/websocket'
import clearTabIndex from '@/utils/clearTabIndex.js'
clearTabIndex.install(Vue)
Vue.prototype.$websocket = websocket;

Vue.use(ElementUI);
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {

    return originalPush.call(this, location).catch((err) => err);
};

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
