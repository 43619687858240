import {reqMenuInfo} from '@/api/sys/menuApi';
import router from '@/router/index';

const state = {

    menuInfo: [],
    isLogin: false,
    userInfo:[]
};

const getters = {

    menuInfo: state => {

        return state.menuInfo;
    },
    isLogin: state => {

        return state.isLogin;
    },
    userInfo: state=> {

        return state.userInfo;
    }
};

const mutations = {

    saveMenuInfo(state, menuInfo) {

        if (router.currentRoute.path === '/home') {

            let children = [];
            if (menuInfo != null) {

                for (let menu of menuInfo) {

                    for (let item of menu.subMenuList) {

                        let obj = {

                            name: item.name,
                            path: item.path,
                            component: () => import("../../views/" + item.component),
                            meta: {

                                requiresAuth: item.requiresAuth,
                                parentName: menu.name
                            }
                        };
                        children.push(obj);
                    }
                }
            }
            router.addRoutes([{

                path: '/home',
                component: () => import('../../views/Home.vue'),
                meta: { requiresAuth: true },
                children: children
            }]);
        }
        state.menuInfo = menuInfo;
    },
    saveIsLogin(state, isLogin) {

        state.isLogin = isLogin;
    },
    saveUserInfo(state, userInfo) {

        state.userInfo = userInfo;
    }
};

const actions = {

    async menuList({commit}) {

        try{

            const res = await reqMenuInfo({platform: 'es-manager'});
            commit('saveMenuInfo', res);
        } catch(err) {

            console.error(err.message)
        }
    }
};

export default {

    state,
    getters,
    actions,
    mutations
}
