<template>
    <div id="app" >
        <router-view/>
    </div>
</template>

<script>
    export default {

        name: 'App',
        created () {

            // 重新加载store中的信息
            if (sessionStorage.getItem("store")) {

                this.$store.replaceState(Object.assign({}, self.state, JSON.parse(sessionStorage.getItem("store"))));
                // 页面刷新之后跳转至系统主页
                this.$router.push({path: '/home'});
            }
            // 刷新页面时将store中保存至sessionStorage，保证登陆信息始终存在
            window.addEventListener("beforeunload", () => {

                sessionStorage.setItem("store", JSON.stringify(this.$store.state));
            });
        }
};
</script>

<style lang="scss">
    body {

        margin: 0;
        padding: 0;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
    }
    #app{

        position: absolute;
        height:100%;
        width: 100%;
        overflow: hidden;
    }

    body .el-table th.gutter{

        display: table-cell!important;
    }

    .panel-style {

        border-radius:4px;
        border: 1px solid #CCC;
        margin: 5px;
        padding: 15px;
        background-color: #fff;
    }
</style>
