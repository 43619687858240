import axios from 'axios';
import global from '../../components/Global';

let doartSystemBase = global.doartSystemBase

export const addSysMenuInfo = params => {

    return axios.post(doartSystemBase + '/sysMenu/addSysMenuInfo', params)
};

export const deleteSysMenuInfo = params => {

    return axios.post(doartSystemBase + '/sysMenu/deleteSysMenuInfo', params)
};

export const updateSysMenuInfo = params => {

    return axios.post(doartSystemBase + '/sysMenu/updateSysMenuInfo', params)
};

export const queryMenuInfoById = params => {

    return axios.post(doartSystemBase + '/sysMenu/querySysMenuInfoById', params);
};

export const queryMenuInfoByParentId = params => {

    return axios.post(doartSystemBase + '/sysMenu/querySysMenuInfoByParentId', params)
};

export const reqMenuInfo = (params) => {

    return axios.post(doartSystemBase + '/sysMenu/queryPlatformSysMenuInfo').then(res => res.data);
};
